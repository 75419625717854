<template>
  <div>
    <el-card shadow="never" style="border: 0" class="mt20">
      <div slot="header" class="clearfix">
        <span>分销用户排名</span>
        <el-select clearable class="ml20" v-model="userType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="nickeName"
          class="w220 ml20 mr20"
          placeholder="请输入用户名称"
        ></el-input>
        <el-button @click='searchFun'>搜索</el-button>
      </div>
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange='handleCurrentChange'
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column
            align="center"
            type="index"
            label="排名"
            show-overflow-tooltip
          />
          <el-table-column
            prop="name"
            align="center"
            label="分销者名称"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.name ? scope.row.name : scope.row.nickeName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="commissionRate"
            align="center"
            label="联系方式"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.bcPhone ? scope.row.bcPhone : scope.row.phone }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sumBalance"
            align="center"
            label="总佣金"
            show-overflow-tooltip
          />
          <el-table-column
            prop="sharesNum"
            align="center"
            label="分享次数"
            show-overflow-tooltip
          />
          <el-table-column
            prop="buyerNum"
            align="center"
            label="购买次数"
            show-overflow-tooltip
          />
          <el-table-column
            prop="completeTime"
            align="center"
            label="购买率"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.sharesNum == 0
                  ? 0
                  : (scope.row.buyerNum / scope.row.sharesNum).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="用户属性"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.userType == 1 ? "员工" : "普通用户" }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
  </div>
</template>

<script>
import { userRank } from "@/api/distribution";
import commonTable from "@/components/common/commonTable";
export default {
  data() {
    return {
      options: [
        { value: 1, label: "员工" },
        { value: 2, label: "普通用户" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      userType: "",
      nickeName: "",
    };
  },
  components: {
    commonTable,
  },
  created() {
    this.userRank();
  },
  methods: {
    searchFun(){
      this.currentPage=1
      this.userRank();
    },
    /**@method 查询分销用户 */
    async userRank() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickeName: this.nickeName,
        userType: this.userType,
      };
      try {
        this.loading = true;
        const result = await userRank(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        //-console.log(error);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.userRank();
    },
    handleSizeChange(val) {
    this.currentPage = 1;
      this.pageSize = val;
      this.userRank();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>